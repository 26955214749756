import apiFunc from '@/api/index'
import { ref, onMounted, watch, computed } from 'vue'

const api = apiFunc()

export default function useOS () {
  /* DATA */
  const operatingSystems = ref([])
  const licenses = ref([])
  const operatingSystemsModel = ref(null)
  const licensesModel = ref(null)
  const licensesDisabled = ref(true)

  /* COMPUTED */

  const receipt = computed(() => {
    if (!operatingSystemsModel.value) {
      return []
    }

    return [
      {
        title: 'Дополнительные опции',
        options: [
          {
            key: 'Операционная система',
            val: operatingSystems.value.find(i => i.value === operatingSystemsModel.value)?.text
          },
          {
            key: 'Лицензия',
            val: licenses.value.find(i => i.value === licensesModel.value)?.text
          }
        ].filter(i => i.val)
      }
    ]
  })

  const requestBody = computed(() => {
    return {
      type: operatingSystemsModel.value,
      license: licensesModel.value
    }
  })

  const total = computed(() => {
    const osPrice = operatingSystems.value.find(i => i.value === operatingSystemsModel.value)?.price ?? 0
    const licensesPrice = licenses.value.find(i => i.value === licensesModel.value)?.price ?? 0

    return osPrice + licensesPrice
  })

  /* METHODS */

  const fetchOS = async () => {
    const res = await api.services.operatingSystems()
    const list = res.data?.list ?? []

    operatingSystems.value = list.map(item => ({
      value: item.id,
      text: item.label,
      price: item.price
    }))

    /* Устанавливаю значение по умолчанию */
    if (operatingSystems.value.length) {
      operatingSystemsModel.value = operatingSystems.value[0].value
    }
  }

  const fetchLicenses = async (id) => {
    try {
      const res = await api.services.licenses(id)
      const data = res.data?.list ?? []

      licenses.value = data.map(item => ({
        value: item.id,
        text: item.label,
        price: item.price
      }))
    } catch (e) {
      console.log(e)
    }
  }

  /* WATCH */

  watch(operatingSystemsModel, async (val) => {
    licensesModel.value = null
    licensesDisabled.value = true
    await fetchLicenses(val)

    if (licenses.value.length) {
      licensesDisabled.value = false
    }
  })

  onMounted(() => {
    fetchOS()
  })

  return {
    operatingSystems,
    licenses,
    operatingSystemsModel,
    licensesModel,
    licensesDisabled,
    receipt,
    requestBody,
    total
  }
}

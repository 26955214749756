<template>
  <main class="vds">
    <AppPreview
      title="VDS"
      text="Ваш сервер любой конфигурации на отказоустойчивой площадке."
    >
      <img src="/images/cloud-store.png" alt="preview">
    </AppPreview>

    <section class="info">
      <div class="container">
        <h2>Инфраструктура будущего - в облаке</h2>

        <p class="info__text">
          В эру победившего «клауда» вопрос перехода от информационных систем on-premise к подлинно облачной модели становится определяющим. Поэтому Вам будут нужны проверенные и безопасные сервисы для решения конкретных задач, созданные заказчиками и партнерами.
        </p>
      </div>
    </section>

    <section class="vds-config">
      <div class="container">
        <h2>Гибкая настройка конфигурации</h2>

        <div class="vds-config__items">
          <div
            v-for="config of configs"
            :key="config.id"
          >
            <BaseSwitches
              :modelValue="config.id === selected"
              :label="config.title"
              :theme="config.theme"
              @update:modelValue="onCheckConfig($event, config.id)"
            />

            <ConfigRow
              v-for="(item, key) in config.rows"
              :key="key"
              v-model="item.model"
              v-model:checked="item.checked"
              :title="item.title"
              :icon="item.icon"
              :min="item.min"
              :max="item.max"
              :step="item.step"
              :theme="config.theme"
              :disabled="config.id !== selected"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="additional">
      <div class="container">
        <h2>Дополнительные опции</h2>

        <div class="row">
          <div class="item">
            <div class="text">Операционная система</div>
            <div>
              <BaseSelect
                v-model="operatingSystemsModel"
                :options="operatingSystems"
              />
            </div>
          </div>
          <div class="item">
            <div
              class="text"
              :class="{ text_disabled: licensesDisabled }"
            >
              Лицензия
            </div>
            <div>
              <BaseSelect
                v-model="licensesModel"
                :options="licenses"
                :disabled="licensesDisabled"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="backup-section">
      <div class="container">
        <BaseSwitches v-model="backupEnabled">
          <template #label>
            <h2>{{ backupExtraData.title }}</h2>
          </template>
        </BaseSwitches>

        <div class="desc">
          {{ backupExtraData.desc }}
        </div>

        <div class="calc">
          <div
            v-for="(item, index) of backupItems"
            :key="index"
            class="calc-row"
          >
            <p class="calc-row__title">{{ item.title }}</p>
            <BaseInputRange
              v-model="item.model"
              :min="item.min"
              :max="item.max"
              :step="item.step"
              :disabled="!backupEnabled"
              class="calc-row__slider"
            />
            <p class="calc-row__val">{{ item.model }} {{ item.unit }}</p>
          </div>
        </div>
      </div>
    </section>

    <section class="cloud-store">
      <div class="container">
        <BaseSwitches v-model="cloudStoreEnabled">
          <template #label>
            <h2>{{ cloudStoreExtraData.title }}</h2>
          </template>
        </BaseSwitches>

        <div class="cloud-store-items">
          <CalcItem
            v-for="item of cloudStoreList"
            :key="item.id"
            v-model:current="item.current"
            v-model:enabled="item.enabled"
            :min="item.min"
            :max="item.max"
            :step="item.step"
            :label="item.label"
            :unit="item.unit"
            :globalEnabled="cloudStoreEnabled"
          />
        </div>
      </div>
    </section>

    <section
      class="container"
      style="margin-top: 60px;"
    >
      <PanelTotal
        :price="globalTotal"
        @open="$refs.requestModal.open()"
      />
    </section>

    <ModalRequest
      :total="globalTotal"
      ref="requestModal"
      @submit="onSubmit"
    >
      <AppReceipt
        :receipt="globalReceipt"
      />
    </ModalRequest>

    <ModalThank ref="modalThank" />
  </main>
</template>

<script>
import useVDS from '@/composables/useVDS'
import useBackup from '@/composables/useBackup'
import useCloudStore from '@/composables/useCloudStore'
import useOS from '@/composables/useOS'

import BaseInputRange from '@/components/Base/BaseInputRange'
import BaseSwitches from '@/components/Base/BaseSwitches'
import BaseSelect from '@/components/Base/BaseSelect'
import AppPreview from '@/components/AppPreview'
import ConfigRow from '@/components/ConfigRow'
import CalcItem from '@/components/CalcItem'
import PanelTotal from '@/components/PanelTotal'
import ModalRequest from '@/components/ModalTemplates/ModalRequest'
import ModalThank from '@/components/ModalTemplates/ModalThank'
import AppReceipt from '@/components/AppReceipt'

export default {
  name: 'PageVDS',
  components: {
    BaseInputRange,
    BaseSwitches,
    BaseSelect,
    AppPreview,
    ConfigRow,
    CalcItem,
    PanelTotal,
    ModalRequest,
    ModalThank,
    AppReceipt
  },
  setup () {
    const {
      selected,
      configs,
      onCheckConfig,
      total: vdsTotal,
      receipt: vdsReceipt,
      requestBody: vdsRequestBody
    } = useVDS()

    const {
      operatingSystems,
      licenses,
      operatingSystemsModel,
      licensesModel,
      licensesDisabled,
      receipt: osReceipt,
      requestBody: osRequestBody,
      total: osTotal
    } = useOS()

    const {
      enabled: backupEnabled,
      receipt: backupReceipt,
      total: backupTotal,
      requestBody: backupRequestBody,
      extraData: backupExtraData,
      items: backupItems
    } = useBackup()

    const {
      enabled: cloudStoreEnabled,
      extraData: cloudStoreExtraData,
      receipt: cloudStoreReceipt,
      requestBody: cloudStoreRequestBody,
      list: cloudStoreList,
      price: cloudStoreTotal
    } = useCloudStore()

    return {
      selected,
      configs,
      onCheckConfig,
      vdsTotal,
      vdsReceipt,
      vdsRequestBody,

      operatingSystems,
      licenses,
      operatingSystemsModel,
      licensesModel,
      licensesDisabled,
      osReceipt,
      osRequestBody,
      osTotal,

      backupEnabled,
      backupExtraData,
      backupTotal,
      backupRequestBody,
      backupReceipt,
      backupItems,

      cloudStoreEnabled,
      cloudStoreExtraData,
      cloudStoreReceipt,
      cloudStoreRequestBody,
      cloudStoreList,
      cloudStoreTotal
    }
  },
  computed: {
    globalTotal () {
      return this.vdsTotal + this.backupTotal + this.cloudStoreTotal + this.osTotal
    },
    globalReceipt () {
      return [
        ...this.vdsReceipt,
        ...this.osReceipt,
        ...this.backupReceipt,
        ...this.cloudStoreReceipt
      ]
    }
  },
  methods: {
    async onSubmit (e) {
      const { surname, name, patron, companyName, email, phone } = e

      try {
        const res = await this.$api.common.sendClientData({
          surname, name, patronymic: patron, companyName, email, phone
        })
        const applicationId = res.data?.applicationId

        await this.$api.services.sendVDS({
          applicationId,
          service: this.vdsRequestBody,
          os: this.osRequestBody,
          backup: this.backupRequestBody,
          cloudStorage: this.cloudStoreRequestBody,
          price: this.globalTotal
        })

        this.$refs.requestModal.close()
        this.$refs.requestModal.clear()
        this.$refs.modalThank.open()
      } catch (e) {
        console.log(e)
        alert('Произошла ошибка!')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vds {
  .info {
    padding: 40px 0;
    &__text {
      font-size: 18px;
      line-height: 1.6;
      margin-top: 40px;
      max-width: 970px;
    }
  }

  &-config {
    &__items {
      margin-top: 24px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 30px;

      @media screen and (max-width: $media-md) {
        grid-template-columns: 1fr;
        grid-column-gap: 0;
        grid-row-gap: 20px;
      }
    }
  }

  .additional {
    margin-top: 80px;

    .row {
      margin-top: 40px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 50px;

      @media screen and (max-width: $media-md) {
        grid-template-columns: 1fr;
        grid-column-gap: 0;
        grid-row-gap: 20px;
      }

      @media screen and (max-width: $media-esm) {
        grid-row-gap: 32px;
      }

      .item {
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-column-gap: 50px;
        align-items: center;

        @media screen and (max-width: $media-sm) {
          grid-template-columns: 2fr 3fr;
        }

        @media screen and (max-width: $media-sm) {
          grid-template-columns: 1fr;
          grid-gap: 12px;
        }

        .text {
          font-weight: 500;

          &_disabled {
            color: #ABABAB;
          }
        }
      }
    }
  }

  .cloud-store {
    margin-top: 80px;

    &-items {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;
      margin-top: 22px;

      @media screen and (max-width: $media-md) {
        grid-template-columns: 1fr;
      }
    }
  }

  .backup-section {
    margin-top: 80px;

    // Стили находятся в файле layouts/default.vue
  }
}
</style>

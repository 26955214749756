import apiFunc from '@/api/index'
import { ref, computed, onMounted } from 'vue'

const api = apiFunc()

/* Костыль из-за неправильной реализации макета и бека */
/* < ----------- Переделать в будующем ----------- > */
const standartID = 1
const goldID = 2
/* < ----------- Переделать в будующем ----------- > */

export default function useVDS () {
  /* DATA */
  const selected = ref(null)
  const configs = ref([])

  /* COMPUTED */

  /**
   * Цена услуги(Итого)
   * */
  const total = computed(() => {
    const currentRate = configs.value.find(i => i.id === selected.value)

    if (!currentRate) { return 0 }

    let total = 0

    for (const key in currentRate.rows) {
      const curItem = currentRate.rows[key]

      if (curItem.checked === false) { continue }

      total += curItem.price * curItem.model
    }

    return total
  })

  /**
   * Инфа для модалки при оформлении заказа
   * */
  const receipt = computed(() => {
    const options = []
    const currentItem = configs.value.find(i => i.id === selected.value)

    if (!currentItem) { return [] }

    for (const key in currentItem.rows) {
      if (currentItem.rows[key].checked !== false) {
        options.push({
          key: currentItem.rows[key].title,
          val: currentItem.rows[key].model + ' Гб.'
        })
      }
    }

    return [
      {
        title: `Инфраструктура в облаке (${currentItem.title})`,
        options
      }
    ]
  })

  const requestBody = computed(() => {
    const currentItem = configs.value.find(i => i.id === selected.value)

    const ssd = currentItem.rows.ssd.checked ? {
      id: currentItem.rows.ssd.id,
      quantity: currentItem.rows.ssd.model
    } : null

    const sas = currentItem.rows.sas.checked ? {
      id: currentItem.rows.sas.id,
      quantity: currentItem.rows.sas.model
    } : null

    return {
      id: currentItem.id,
      cpu: {
        id: currentItem.rows.cpu.id,
        quantity: currentItem.rows.cpu.model
      },
      ram: {
        id: currentItem.rows.ram.id,
        quantity: currentItem.rows.ram.model
      },
      ssd,
      sas
    }
  })

  /* METHODS */
  const fetchVDS = async () => {
    const res = await api.services.fetchVDS()
    const list = res.data?.list ?? []

    const standart = list.find(i => i.id === standartID)
    const gold = list.find(i => i.id === goldID)

    selected.value = standart.id

    configs.value = [
      {
        id: standart.id,
        title: 'Стандарт',
        rows: {
          cpu: {
            title: 'vCPU',
            icon: 'chip',
            id: standart.cpu.id,
            model: standart.cpu.min,
            min: standart.cpu.min,
            max: standart.cpu.max,
            step: standart.cpu.step,
            price: standart.cpu.price
          },
          ram: {
            title: 'RAM',
            icon: 'ram',
            id: standart.ram.id,
            model: standart.ram.min,
            min: standart.ram.min,
            max: standart.ram.max,
            step: standart.ram.step,
            price: standart.ram.price
          },
          ssd: {
            title: 'SSD',
            checked: true,
            id: standart.ssdDisk.id,
            model: standart.ssdDisk.min,
            min: standart.ssdDisk.min,
            max: standart.ssdDisk.max,
            step: standart.ssdDisk.step,
            price: standart.ssdDisk.price
          },
          sas: {
            title: 'SAS',
            checked: true,
            id: standart.sasDisk.id,
            model: standart.sasDisk.min,
            min: standart.sasDisk.min,
            max: standart.sasDisk.max,
            step: standart.sasDisk.step,
            price: standart.sasDisk.price
          }
        }
      },
      {
        id: gold.id,
        theme: 'gold',
        title: 'Gold',
        rows: {
          cpu: {
            title: 'vCPU',
            icon: 'chip',
            id: gold.cpu.id,
            model: gold.cpu.min,
            min: gold.cpu.min,
            max: gold.cpu.max,
            step: gold.cpu.step,
            price: gold.cpu.price
          },
          ram: {
            title: 'RAM',
            icon: 'ram',
            id: gold.ram.id,
            model: gold.ram.min,
            min: gold.ram.min,
            max: gold.ram.max,
            step: gold.ram.step,
            price: gold.ram.price
          },
          ssd: {
            title: 'SSD',
            checked: true,
            id: gold.ssdDisk.id,
            model: gold.ssdDisk.min,
            min: gold.ssdDisk.min,
            max: gold.ssdDisk.max,
            step: gold.ssdDisk.step,
            price: gold.ssdDisk.price
          },
          sas: {
            title: 'SAS',
            checked: true,
            id: gold.sasDisk.id,
            model: gold.sasDisk.min,
            min: gold.sasDisk.min,
            max: gold.sasDisk.max,
            step: gold.sasDisk.step,
            price: gold.sasDisk.price
          }
        }
      }
    ]
  }

  /**
   * Логика переключения свитчера
   * */
  const onCheckConfig = (bool, id) => {
    if (bool) {
      selected.value = id
    } else {
      if (id === standartID) {
        selected.value = goldID
      } else
      if (id === goldID) {
        selected.value = standartID
      }
    }
  }

  /* MOUNTED */
  onMounted(() => {
    try {
      fetchVDS()
    } catch (e) {
      console.log(e)
    }
  })

  return {
    receipt,
    selected,
    configs,
    onCheckConfig,
    requestBody,
    total
  }
}
